import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'picturefill';
import 'picturefill/dist/plugins/intrinsic-dimension/pf.intrinsic';
import 'picturefill/dist/plugins/mutation/pf.mutation';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

const RedirectToAesop = () => {

    useEffect(() => {
        window.location.href = "https://www.aesop.com/r/aesop-foundation";
    }, [])

    return null;
}

ReactDOM.render(<RedirectToAesop />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
